import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/nl/analytics-consulting",
  "Event Tracking": "/nl/event-tracking"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/event-tracking"
  },
  {
    hreflang: "de",
    href: "/de/event-tracking"
  },
  {
    hreflang: "se",
    href: "/se/händelsespårning"
  },
  {
    hreflang: "da",
    href: "/da/event-sporing"
  },
  {
    hreflang: "no",
    href: "/no/hendelsessporing"
  },
  {
    hreflang: "nl",
    href: "/nl/event-tracking"
  },
  {
    hreflang: "x-default",
    href: "/en/event-tracking"
  }
];

const EventTracking = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Event Tracking in Google Analytics [Setup Guide ${currentYear}]`}
        description="Gids voor het configureren van event tracking voor moderne Google Analytics samen met Google Tag Manager. Handleiding met screenshots en uitleg van een gecertificeerde expert."
        lang="nl"
        canonical="/nl/event-tracking"
        image="google-analytics-event-tracking-report-hero"
        alternateLangs={alternateLangs}
        datePublished="2024-06-11T04:32:43.188Z"
        dateModified="2024-06-11T06:56:07Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-hero.png"
          alt="event data grafiek van Google Analytics event rapportage"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Event Tracking</H>
        <p>
          Het configureren van Google Analytics event tracking is essentieel voor data-analyse, omdat het wordt gebruikt om gebruikersinteracties te volgen. Het analyseren van pageviews, sessies en bezoekers geeft slechts beperkt inzicht in de betrokkenheid van een websitebezoeker.
        </p>{" "}
        <p>
          Dit wordt opgelost door aanvullende gegevens te verzamelen van aangepaste events die gebruikersinteracties vastleggen, zoals bijvoorbeeld klikken op links.
        </p>
        <p>
          Events vertegenwoordigen gebruikersinteracties of gebeurtenissen.
          <br />
          Meestal staan de belangrijkste interacties in relatie tot het doel van de website.
          <br /> Daarom stellen we aangepaste event tracking in om het te gebruiken als prestatie-indicator om te zien of onze doelen worden bereikt.
        </p>
        <p>
          Events kunnen worden gegroepeerd in <strong>harde conversies</strong> en <strong>zachte conversies</strong> om hun relatie met het algehele doel van de website weer te geven.
        </p>
        <p>
          Omdat events vaak de basis vormen voor het monitoren van conversies in een e-commerce of remarketing context, wordt het soms conversietracking genoemd.
          <br />Ik maak een onderscheid tussen conversietracking en het volgen van een event, aangezien een conversie ook een pageview of een <b>passieve</b> gebeurtenis kan zijn, zoals bijvoorbeeld het doorbrengen van een bepaalde tijd op een landingspagina.
        </p>
        <p>
          Om te illustreren wat event tracking betekent, laat ik je zien hoe je <strong>click tracking</strong> instelt op een verzendknop. Daarna zou je in staat moeten zijn om je eigen unieke klikgebeurtenissen in te stellen op elk element dat je wilt - dus laten we beginnen!
        </p>
        <H as="h2">Wat is event tracking in Google Analytics?</H>
        <p>
          Een event kan zowel een <strong>actieve gebruikersinteractie</strong> als een <strong>passieve gebeurtenis</strong> zijn. Het volgen ervan betekent het observeren of tellen van de gebeurtenissen en deze in relatie tot gebruikers, bezoeken en andere dimensies voor latere analyse plaatsen.
        </p>
        <p>Omdat je vrij bent om events naar wens te configureren, kunnen ze vele verschillende vormen aannemen.</p>
        <H as="h3">Voorbeelden</H>
        <ul>
          <li>een product toevoegen aan een winkelwagen</li>
          <li>inschrijven voor een e-maillijst</li>
          <li>naar beneden scrollen tot het einde van een artikel</li>
          <li>op play klikken in een video</li>
          <li>gebruikerslogin</li>
          <li>op een afbeelding klikken om in te zoomen</li>
          <li>een contactformulier verzenden</li>
          <li>op een externe link klikken</li>
          <li>een artikel afdrukken</li>
          <li>een bestand downloaden</li>
        </ul>
        <p>
          Zoals je kunt zien, zijn events meestal gekoppeld aan iets wat de gebruiker <em>doet</em>, gebaseerd op monitoring...
        </p>
        <ul>
          <li>muis klikken</li>
          <li>muis bewegingen</li>
          <li>scrollen</li>
          <li>browser navigatie</li>
          <li>toetsenbord invoer</li>
        </ul>
        <p>
          Events kunnen ook <em>passief</em> zijn, zonder dat de gebruiker actief iets doet.
          <br />
          Bijvoorbeeld wanneer de gebruiker een bepaalde <strong>tijd op een pagina doorbrengt</strong> of wanneer een formulier een <strong>foutmelding</strong> geeft. Ook wanneer een <strong>chatbotvenster</strong> opent om met de bezoeker te praten, komt er geen activiteit van de gebruiker.
        </p>
        <p>Hoewel een <b>paginaweergave</b> technisch gezien ook een interactie is, telt de meest gebruikte Universal Google Analytics een paginaweergave niet als een event. De nieuwe Google Analytics App + Web (momenteel in Beta) gebruikt echter een ander datamodel dat paginabezoeken ook als events bijhoudt.</p>
        <H as="h2">Event types</H>
        <p>We kunnen twee soorten events onderscheiden:</p>
        <H as="h6">Actieve events (met gebruikersinteractie)</H>
        <ul>
          <li>muisklikken, scroll tracking en muisbewegingen</li>
          <li>scherm aanraken</li>
          <li>toetsenbord invoer</li>
        </ul>
        <H as="h6">Passieve events</H>
        <ul>
          <li>een vooraf gedefinieerde tijdsdrempel bereiken</li>
          <li>Serverreacties</li>
          <li>Programmagebaseerde validaties</li>
          <li>Uitvoering van scripts van derden</li>
        </ul>
        <p>
          Let op dat je met het volgen van actieve en passieve events een groot deel van de{" "}
          {/* <a href="/customer-journey"> */}
          klantreis
          {/* </a> */}
          kunt reconstrueren. <br />
          Dergelijke gegevens stellen je in staat om conclusies te trekken over de gebruikerservaring van begin tot eind. Als je fouten in de reis vindt, kun je nu actie ondernemen om die negatieve ervaringen te verminderen.
        </p>
        <H as="h2">Structuur van Google Analytics Events</H>
        <p>
          Elk event in Google Analytics bevat enkele eventvelden, of informatiebits over het event, die we kunnen gebruiken om de gebeurtenis te beschrijven:
        </p>
        <ul>
          <li>
            <strong>event category</strong> - om events in groepen te categoriseren. In de context van een contactformulier, bijvoorbeeld, wil je alle events groeperen in een eventcategorie <em>contactformulier</em>
          </li>
          <li>
            <strong>event action</strong> - om de interactie te beschrijven, bijvoorbeeld <em>formulierinzending</em>
          </li>
          <li>
            <strong>event label</strong> - om extra informatie over de interactie toe te voegen. Bijvoorbeeld, als de gebruiker het <em>adres van hun website</em> samen met het formulier verstuurt, gebruik je dit om onderscheid te maken tussen de ontvangen formulieren
          </li>
          <li>
            <strong>event value</strong> - (<em>optioneel</em>) om een numerieke waarde aan de informatie toe te voegen. Bijvoorbeeld, als gemiddeld elke 25e contact leidt tot een conversie van 100$, kunnen we een waarde van <em>4</em> toekennen aan elk event.
          </li>
          <li>
            <strong>event non-interaction</strong> - om onderscheid te maken tussen een actieve of passieve gebruikersinteractie. <br />
            Standaard is deze ingesteld op <code>false</code>, wat betekent dat het event als actief telt. <strong>Belangrijk:</strong> Actieve events beïnvloeden de berekening van de <b> <Link to="/nl/wiki-analytics/bouncepercentage">bouncepercentage</Link></b> van een pagina, door deze te verlagen. Dus als je ooit worstelt met een onrealistisch lage bounce rate, is de kans groot dat sommige events ten onrechte zijn ingesteld op <code>true</code>.
          </li>
        </ul>
        <H as="h2">Google Analytics event tracking implementeren</H>
        <p>Er zijn twee mogelijke manieren om aangepaste event tracking te implementeren:</p>
        <ol>
          <li>
            <strong>Implementeer het</strong> met javascript snippets op alle relevante HTML-elementen samen met een onclick event handler - <strong>Ik raad deze aanpak niet aan</strong>, omdat deze foutgevoelig en niet schaalbaar is. Als je dit toch wilt doen, kun je codevoorbeelden vinden in{" "}
            <strong>Google’s documentatie over event tracking</strong>:
          </li>
        </ol>
        <ul>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
              analytics.js event documentatie (legacy)
            </a>
          </li>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
              gtag.js event documentatie
            </a>
          </li>
        </ul>
        <ol start="2">
          <li>
            <strong>Instellen met Google Tag Manager</strong> of een ander <Link to="/nl/tagbeheer">tag management systeem</Link> van jouw keuze. <br />
            Gemak en flexibiliteit bij het doorvoeren van wijzigingen, evenals schaalbaarheid en eenvoudige integratie met
            diensten van derden maken het de geprefereerde implementatiemethode.
          </li>
        </ol>
        <p>
          Ik zal uitleggen hoe je events kunt volgen met Google Tag Manager, aangezien het het meest gebruikte tag
          management systeem is dat iedereen kan gebruiken.
        </p><p>Als je meer wilt leren over GTM, zoals hoe je het instelt en dingen zoals uitgaande links en downloads volgt, lees dan mijn <Link to="/nl/google-tag-manager-setup">Google Tag Manager handleiding</Link>.</p>
        <H as="h2">Handleiding om events te volgen met Google Tag Manager</H>
        <p>
          Het instellen van aangepaste event tracking kan worden onderverdeeld in het maken van een <strong>trigger</strong> voor het event, evenals het maken van een <strong>event tag</strong>, die de eventeigenschappen (<em>category, action, label</em>, etc.) invult. <br />
          De waarden voor de eventeigenschappen kunnen statisch of dynamisch worden toegewezen - waarbij de laatste de voorkeur heeft voor het opschalen van de configuratie over grotere sites.
        </p>
        <p>
          Als voorbeeld gaan we Google Analytics tracking implementeren voor een knopklik. <strong>Klik tracking</strong> is de meest gebruikte vorm van gebruikersgedrag tracking, dus het voorbeeld zou toepasbaar moeten zijn op veel andere gebruikssituaties.
        </p>
        <p>Op dit punt ga ik ervan uit dat je al een tracking-ID hebt aangemaakt en dat de trackingcode is geïmplementeerd op de hele site toen je <Link to="/nl/google-analytics-installatie">Google Analytics</Link> instelde met GTM.</p>

        <H as="h3">Configureer de event trigger in Google Tag Manager</H>
        <ul>
          <li>
            Eerst moeten we inloggen in onze <a href="https://tagmanager.google.com">Google Tag Manager container</a>{" "}
            en <strong>een nieuwe trigger maken</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
          alt="Nieuwe trigger instellen in Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            Geef je trigger een <strong>naam</strong>, bijvoorbeeld <strong>Klik - Knop</strong>, en klik op het{" "}
            <strong>triggerconfiguratie</strong> veld om een triggertype te selecteren. Je ziet een lijst met triggertypes van rechts inschuiven.
          </li>
          <li>
            Kies <strong>Alle Elementen</strong> als type en selecteer dat de <strong>trigger alleen afgaat bij sommige klikken</strong>.
          </li>
          <li>
            Kies vervolgens in de eerste dropdown <strong>Klik Element</strong>. Als het niet wordt vermeld, klik dan gewoon op <strong>Kies ingebouwde variabele...</strong> en activeer het.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-variable.png"
          alt="Trigger variabele in Google Tag Manager screenshot"
          className="article-img"
        />
        <ul>
          <li>
            In de tweede dropdown, kies <strong>komt overeen met CSS selector</strong>.<br />
            Laat me nu uitleggen wat we aan het doen zijn:
            <br />
            We proberen een kliktrigger te genereren voor een specifiek HTML-element op je website - degene waarvan je de klikken wilt volgen. In mijn voorbeeld is het een <strong>knop</strong>, maar het kan ook een <strong>afbeelding</strong> of een <code>&lt;div&gt;</code> tag zijn, of elk ander HTML-element - dus de vraag is: Hoe vertellen we Google Tag Manager (GTM) welk element te observeren?
            <br />
            Het antwoord is: <strong>CSS selectors</strong> - Weet je niet wat dat is? Het is gewoon een programmatische manier om HTML-elementen op de pagina te specificeren en ik zal je een manier laten zien om de CSS selector voor elk element te krijgen met slechts een paar muisklikken.
          </li>
          <li>
            Klik met de <strong>rechtermuisknop</strong> op een element in de Google Chrome browser waarop je de klikken wilt volgen en selecteer <strong>inspecteren</strong>. Chrome Developer Tools zullen openen en standaard zal het HTML-element vooraf geselecteerd zijn.
          </li>
          <li>
            Klik vervolgens eenvoudig met de <strong>rechtermuisknop</strong> op het element en kies <strong>Kopiëren</strong> &gt; <strong>Kopieer JS-pad</strong> (vermijd <em>kopieer selector</em> - dit geeft soms foutieve selectors terug).
          </li>
        </ul>
        <ImgContainerFixed width="529px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
            alt="CSS selector kopiëren voor knopklik tracking"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Als je de inhoud van je klembord in een teksteditor plakt, zie je dat je iets hebt gekopieerd zoals:
            <br />
            <br />
            <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
            <br />
            <br />
            We willen het deel dat tussen de haakjes staat zonder de aanhalingstekens. Dus dit deel:
            <br />
            <br />
            <code>body &gt; ul &gt; button</code>
          </li>
          <li>
            <strong>Dit is de CSS Selector</strong> die we moeten plakken in het laatste veld in onze triggerconfiguratie in GTM (<em>zie hieronder</em>).
            <br />
            <strong>Kopieer</strong> die waarde en plak deze erin. Klik op <strong>opslaan</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Event trigger configuratie in Google Tag Manager screenshot"
          className="article-img"
        />
        <p>Merk op dat je dit met elk element kunt doen en elke event trigger in GTM kunt instellen zoals je wilt?</p>
        <p>
          We zijn nog niet klaar. Na het instellen van de triggercondities voor ons event, moeten we de Google Analytics event tag configureren om ervoor te zorgen dat de juiste gegevens naar de Google Analytics-property worden verzonden.
        </p>
        <H as="h3">Maak een aangepaste event tag</H>
        <p>
          Nu we een trigger hebben, moeten we deze combineren met een daadwerkelijke event tag. Je kunt triggers en tags beschouwen als het <em>wanneer</em> en <em>wat</em> in ons voorbeeld: <br /> De trigger definieert <em>wanneer</em> we een event willen volgen (<em>waneer onze knop wordt geklikt</em>), en nu zullen we een GA event definiëren, dat beschrijft <em>wat</em> we doen: <em>Stuur een event naar GA inclusief alle juiste waarden voor de eventeigenschappen.</em>
        </p>
        <p>Laten we beginnen.</p>
        <ul>
          <li>
            Ga in Google Tag Manager naar het tags-menu en <em>maak een nieuwe tag</em>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
          alt="Google Tag Manager maak aangepaste event tag"
          className="article-img"
        />
        <ul>
          <li>
            Geef de tag een <strong>naam</strong> zoals <em>Knop Event</em> en klik op{" "}
            <strong>tag configuratie</strong>.
          </li>
          <li>
            Een paneel aan de rechterkant zal inschuiven met een lijst van mogelijke tagtypes om uit te kiezen. Selecteer{" "}
            <strong>Google Analytics - Universal Analytics</strong>.
          </li>
          <li>
            Terug in de tagconfiguratie, stel het <strong>track type</strong> in op <strong>event</strong>. Nu zullen
            er meer velden verschijnen voor de eventgegevens.
          </li>
          <li>
            Laten we statische waarden toewijzen voor <strong>event category</strong> en <strong>action</strong> en laten we de <strong>event label</strong> dynamisch invullen.
            <br />
            Noem de <strong>category</strong> bijvoorbeeld <em>klik events</em>, de <strong>action</strong> is gewoon <em>klik</em> en voor het <strong>label</strong> klik je op het Lego blok symbool ernaast.
          </li>
          <li>
            We willen dat het label altijd de tekst bevat die op het aangeklikte HTML-element staat, zodat we bij het volgen van meerdere knoppen onderscheid kunnen maken tussen hen.
            <br />
            Na het klikken op het Lego blok, zal een paneel inschuiven om een <strong>variabele te kiezen</strong>. Klik op <strong>built-ins</strong> in de rechterbovenhoek, tenzij je al <strong>klik tekst</strong> in de lijst met variabelen ziet staan (kies het dan gewoon en sla de volgende stap over).
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
          alt="ingebouwde variabele voor event label in google tag manager"
          className="article-img"
        />
        <ul>
          <li>
            In de lijst van ingebouwde variabelen, activeer <strong>klik tekst</strong> en GTM zal die variabele toevoegen in het label veld tussen accolades, zoals dit: <code>{"{{Click Text}}"}</code> - Dubbele accolades zijn de interne manier in GTM om te verwijzen naar variabelen.
          </li>
          <li>
            Het <strong>waarde</strong> veld in de tagconfiguratie kan worden ingevuld met een waarde die de eventklik kan vertegenwoordigen. <br />
            In mijn voorbeeld laat ik het veld <strong>leeg</strong> omdat ik geen betekenisvolle monetaire waarde heb voor de knopklik.
          </li>
          <li>
            Als het event <strong>interactief</strong> is, selecteer <strong>false</strong> voor de non-interaction hit. Als het een <strong>passief event</strong> is, selecteer <strong>true</strong>.
          </li>
          <li>
            In de <strong>Google Analytics settings</strong> variabele, selecteer de variabele die je hebt geconfigureerd toen je{" "}
            <a href="/nl/google-analytics-installatie">Google Analytics instelde</a>.
          </li>
          <li>
            Als je geen <strong>instellingen variabele</strong> hebt geconfigureerd, zet dan gewoon een vinkje bij <em>Instellingen in deze tag overschrijven inschakelen</em> en voeg de tracking-ID toe (bijv. <em>UA-12345678-91</em>) van je{" "}
            <Link to="/nl/google-analytics-installatie">
              Google Analytics tracking code
            </Link>
            .
          </li>
          <li>
            Scroll tenslotte naar de <strong>triggering</strong> configuratie, <strong>klik erop</strong> en selecteer de trigger die we kort geleden hebben geconfigureerd: <em>Klik - Knop</em>
          </li>
        </ul>
        <p>De gehele event tag configuratie in één keer:</p>
        <ImgContainerFixed width="371px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
            alt="GTM event tag configuratie"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Klik nu op <strong>opslaan</strong> in de rechterbovenhoek en je bent klaar met het instellen van het GA event.
          </li>
        </ul>
        <p>Geweldig! De volgende stap is om dubbel te controleren of onze onclick tracking setup werkt.</p>
        <H as="h2">Events debuggen</H>
        <p>
          Om de setup te debuggen, ga naar de <strong>preview modus</strong> vanuit het Google Tag Manager
          overzicht:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-preview.png"
          alt="Google tag manager preview modus"
          className="article-img"
        />
        <br />
        <p>
          Daarna bezoeken we de pagina met de verzendknop. Vanwege de Google Tag Manager preview
          modus, hebben we bepaalde <Link to="/nl/wiki-analytics/cookies">cookies</Link> ingesteld in onze browser, die een debugvenster onderaan de
          pagina openen:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugging-mode.png"
          alt="google tag manager debugger voor events"
          className="article-img"
        />
        <br />
        <p>
          Als je nu <strong>op de knop klikt</strong> om de event tracking te testen, zou je een{" "}
          <strong>klik</strong> moeten zien verschijnen in het linker overzichtspaneel, en het hoofd debugvenster zou moeten laten zien dat ons
          event is afgevuurd:
        </p>
        <ImgContainerFixed width="555px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-button-click.png"
            alt="google tag manager debugger klik tracking"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Als je op het <strong>knop event</strong> klikt, kun je de eventvelden verifiëren die samen met
          de event hit zijn verzonden:
        </p>
        <ImgContainerFixed width="496px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
            alt="google tag manager debugger eventvelden"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Zie je hoe het <strong>event label</strong> automatisch is gevuld met “OK”? Dat komt omdat we de <strong>klik tekst</strong> ingebouwde variabele in Google Tag Manager hebben gebruikt, die altijd de tekst van het aangeklikte element bevat.
        </p>
        <p>Belangrijker nog, als je verder naar beneden scrollt, kun je de regels voor de event trigger verifiëren:</p>
        <ImgContainerFixed width="413px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-trigger-rules.png"
            alt="google tag manager event trigger regels"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          <strong>TIP</strong>: Om te verifiëren dat je Google Analytics-account alle gegevens succesvol heeft ontvangen, kunnen we
          het <strong>realtime rapport voor events</strong> in Google Analytics bezoeken. In het geval dat er al een paar minuten zijn verstreken sinds de laatste klik op de knop, zou het <strong>bekijken van de laatste 30 minuten</strong> moeten helpen.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-realtime-report.png"
          alt="google analytics realtime rapport voor events"
          className="article-img"
        />
        <H as="h3">CSS Selector validatie</H>
        <p>
          In het geval dat de debugger je klikken op de knop niet heeft opgemerkt en geen klikevent heeft weergegeven, is er waarschijnlijk
          iets mis met de <strong>CSS selector</strong>.<br />
          <strong>TIP:</strong> je kunt de <strong>chrome developer tools</strong> openen op de pagina met het element dat je
          wilt volgen door ofwel <strong>F12</strong> in te drukken of met de <strong>rechtermuisknop</strong> te klikken en{" "}
          <strong>inspecteren</strong> te selecteren en naar het tabblad <strong>elementen</strong> te gaan. Als je nu{" "}
          <strong>CTRL+F</strong> of <strong>CMD+F</strong> op een Mac indrukt om naar een element te zoeken, kun je eenvoudig{" "}
          <strong>je CSS selector in het zoekveld plakken</strong> en dev tools zal automatisch naar het element springen:
        </p>
        <ImgContainerFixed width="578px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-find-by-css-selector.png"
            alt="google developer tools vind element met css selector"
            className="article-img"
          />
        </ImgContainerFixed>
        <p>
          Op deze manier kun je verifiëren of je CSS selector werkt. Als dev tools niet naar een element in de code springt,
          is er iets mis met de selector. Er zijn ook <strong>browserextensies</strong> die je kunnen helpen de juiste selector te krijgen.
        </p>
        <p>
          Na het debuggen en corrigeren van selectors zouden we uiteindelijk de kliktracking moeten laten werken en kunnen we teruggaan naar
          Google Tag Manager om de <strong>preview modus te verlaten</strong> en <strong>de wijzigingen te verzenden</strong>.
        </p>
        <p>
          Gefeliciteerd tot nu toe! Je hebt event monitoring voor het klikken op een knop geconfigureerd op basis van een CSS
          selector!
        </p>
        <p>
          Nu de setup werkt, kun je{" "}
          <Link to="/nl/google-analytics-installatie">event doelen instellen</Link> in
          GA! Google Analytics doelen zijn een nuttige functie, omdat je rapporten kunt uitsplitsen en kunt zien hoeveel conversies
          hebben plaatsgevonden voor een bepaald subsegment.
        </p>
        <p>
          Om het in te stellen, hoef je alleen maar een aangepast Google Analytics-doel te definiëren met de categorie-, actie- en labelvelden
          die overeenkomen met je event en dan wordt het als een eventdoel geteld.
        </p>
        <H as="h2">Voordelen &amp; Nadelen van tracking met CSS selectors</H>
        <p>
          Het is belangrijk om te vermelden dat het instellen van event tracking op basis van CSS selectors niet de meest robuuste manier is.
        </p>
        <p>
          De hele event setup zal breken als de CSS selector niet werkt. Het is een <em>kritieke afhankelijkheid</em>,
          wat een zwakte vertegenwoordigt in deze manier van instellen.
        </p>
        <p>Maar waarom zou de selector ooit stoppen met werken?</p>
        <p>
          Omdat hoe groter de website, hoe meer veranderingen er plaatsvinden op een site, en hoe meer mensen erbij betrokken zijn -
          mogelijk tegelijkertijd in verschillende teams.
          <br /> Zodra een verandering de structuur van onze selector beïnvloedt, zal deze stoppen met werken.
        </p>
        <p>
          Een frontend ontwikkelaar kan op een dag in de toekomst enkele wijzigingen aan de pagina toevoegen, waardoor de{" "}
          selector kan breken. Daarom zou het robuuster zijn om een <strong>onclick event handler</strong> toe te voegen aan het
          element, dat alle eventeigenschappen naar een{" "}
          <strong>
            <Link to="/nl/data-laag">data laag</Link>
          </strong>
          pusht. Dit is de aanbevolen aanpak voor belangrijke KPI's en voor grote organisaties, waar veranderingen niet
          overzien worden.
        </p>
        <p>Geavanceerde webanalytics-implementaties die databases en externe gegevensbronnen integreren, gebruiken een <Link to="/nl/data-laag#event-tracking-basend-on-a-data-layer">data layer om events te volgen</Link>. Lees dat artikel voor de benodigde event-tracking code.</p>
        <p>Het gebruik van een data layer is veiliger en betrouwbaarder, maar vereist een grondige implementatie.</p>
        <p>In plaats van te vertrouwen op CSS-selectors, gebruik je event listeners voor <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">browser events</a> als trigger. GTM gebruikt ook klik event listeners onder de motorkap wanneer je een kliktrigger instelt, maar bij het baseren van tracking op een data layer kun je vrijer kiezen welke browser events moeten worden gevolgd.</p>
        <p>Vooral voor een single page app (SPA) zonder paginaladingen tussen paginanavigatie, kan een data layer goed worden gebruikt om <b>virtuele paginaweergaven</b> als events te volgen en deze te laten tellen als reguliere paginaweergaven in Google Analytics.</p>
        <p>
          Voor kleinere websites is de moeite van het implementeren van een data layer niet altijd te rechtvaardigen. In dergelijke gevallen is event tracking op basis van CSS-selectors een geldige aanpak.
        </p>
        <p>
          Het <strong>voordeel</strong> van het gebruik van CSS-selectors is duidelijk de <strong>eenvoud</strong> van implementatie en hoe <strong>snel</strong> je gegevens kunt verzamelen. Dit kan ook nuttig zijn als een data layer-implementatie even kan duren vanwege een tekort aan middelen, omdat je mogelijk al een deel van de gegevens kunt verzamelen met CSS-selectors.
        </p>
        <p>
          Het <strong>nadeel</strong> is dat deze manier bijna <strong>gevoelig is om te breken</strong> op een dag, afhankelijk van hoeveel wijzigingen er worden doorgevoerd en hoeveel teams aan de website werken.
        </p>
        <p>
          Maar als je webtracking voor je eigen site instelt en je hebt de controle over de wijzigingen, voel je dan vrij om deze methode te volgen. Wees je er echter van bewust dat wijzigingen in de HTML-structuur van de pagina de CSS-selector kunnen breken.{" "}
        </p>
        <p>
          Bedrijven met een budget en hoge eisen aan de datakwaliteit zouden echter een data layer als basis voor hun event tracking setup moeten kiezen.
        </p>
        <H as="h2">Eventrapporten in Google Analytics</H>
        <p>
          Nu we tracking hebben ingesteld voor gebruikersinteracties, moeten we ervoor zorgen dat we bovenop de hoeveelheden events blijven die naar het Google Analytics-account worden gestuurd.{" "}
        </p>
        <p>
          De beste manier om dit te doen is met het ingebouwde <strong>eventrapport</strong>, met een <strong>custom rapport</strong> of een Google Analytics <strong>dashboard</strong>.
        </p>
        <p>
          In <strong>Google Analytics</strong> kun je naar <strong>Gedrag</strong> &gt; <strong>Events</strong> &gt; <strong>Top Events</strong> gaan om de eventgegevens te vinden die je hebt gevolgd:
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report.png"
          alt="Google Analytics event tracking rapport overzicht"
          className="article-img"
        />
        <br />
        <p>
          Omdat er geen standaard <b>eventnaam</b> beschikbaar is in GA, zie je eerst je eventcategorieën. Je kunt ook de primaire dimensie kiezen als <strong>eventactie</strong> of <strong>eventlabel</strong> om dieper in het rapport te duiken.
        </p>
        <p>
          Merk op dat het events rapport ons een kolom met <strong>Gem. Waarde</strong> geeft, wat handig is als we de gemiddelde waarde van onze klikevents nodig hebben. Dit kan relevant zijn voor een webapp die prijzen berekent, waarbij het eindresultaat wordt gevolgd met een event. Op deze manier kun je gemakkelijk de gemiddelde waarde van de berekeningen van een gebruiker vinden.{" "}
        </p>
        <p>
          Andere veelvoorkomende voorbeelden van nuttige gem. waarden zijn bijvoorbeeld filterinstellingen voor prijzen of maten in een e-commerce winkel.{" "}
        </p>
        <p>
          Houd dus in gedachten dat een <strong>eventwaarde</strong> in eerste instantie ongerelateerd lijkt, maar er kan toch een gerelateerde waarde zijn om aan het event toe te wijzen, omdat het nuttig zou zijn om gemiddelde waarden over de hele linie te controleren.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default EventTracking;
